import styles from "./index.module.css";
import React, { useState, useEffect } from 'react';
import config from "../config.js";
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [isChecked, setIsChecked] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState(Cookies.get('token') || '');
    const navigate = useNavigate();
    
    useEffect(() => {
        if (token) {
            Cookies.set('token', token, { domain: config.domain });
        } else {
            Cookies.remove('token', { domain: config.domain });
        }
      }, [token]);
  

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${config.api}/login`, {
                username,
                password,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            Cookies.set('token', response.data.access_token);
            // setToken(response.data.access_token);
            // setMessage('Login successful!');
            navigate('/');
        } catch (error) {
            console.log(error);
            // setMessage('Login failed: ' + (error.response?.data?.detail || 'An error occurred'));
        }
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${config.api}/register`, {
                username,
                password,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            // setMessage('Registration successful! Please login.');
            // setIsRegisterMode(false);
            setToken(response.data.access_token);
            navigate('/');
        } catch (error) {
            // setMessage('Registration failed: ' + (error.response?.data?.detail || 'An error occurred'));
        }
    };


    return (
        <div className={styles.container}>
            <div className={styles.main}>
                <input type="checkbox" id={styles.chk} 
                checked={isChecked} 
                onChange={handleCheckboxChange} aria-hidden="true" />

                <div className={styles.signup}>
                    <form>
                        <label onClick={handleCheckboxChange} className={styles.label} htmlFor="chk" aria-hidden="true" style={{
                            transform: isChecked ? 'scale(0.6)' : 'scale(1)',
                        }}>Signup</label>
                        <div className={styles.inputGroup}>
                            <input type="username" name="username" onChange={(e) => {setUsername(e.target.value)}} required="" />
                            <label className={styles.label} htmlFor="username">Username</label>
                        </div>
                        <div className={styles.inputGroup}>
                            <input type="password" name="pswd" onChange={(e) => {setPassword(e.target.value)}} required="" />
                            <label className={styles.label} htmlFor="password">Password</label>
                        </div>
                        <button className={styles.button} onClick={handleRegister}>Signup</button>
                    </form>
                </div>


                <div className={styles.login} style={{
                    transform: isChecked ? 'translateY(-500px)' : 'translateY(-180px)',
                }}>
                    <form>
                        <label onClick={handleCheckboxChange} className={styles.label} htmlFor="chk" aria-hidden="true" style={{ transform: isChecked ? 'scale(1)' : 'scale(0.6)' }}>Login</label>
                            <div className={styles.inputGroup}>
                                <input type="username" name="username" onChange={(e) => {setUsername(e.target.value)}} required="" />
                                <label className={styles.label} htmlFor="username">Username</label>
                            </div>
                            <div className={styles.inputGroup}>
                                <input type="password" name="pswd" onChange={(e) => {setPassword(e.target.value)}} required="" />
                                <label className={styles.label} htmlFor="password">Password</label>
                            </div>

                        <button className={styles.button} onClick={handleLogin}>Login</button>
                    </form>
                </div>
            </div>

            <div className={styles.wavesContainer}>
                <svg className={styles.waves} xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g className={styles.parallax}>
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                    </g>
                </svg>
            </div>
        </div>
    )
}

export default Login;