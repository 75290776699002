import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import config from "./config.js";
import Login from './login';
import Panel from './panel';

function App() {
  // eslint-disable-next-line
    const [token, setToken] = useState(Cookies.get('token') || '');

    useEffect(() => {
      if (token) {
          Cookies.set('token', token, { domain: config.domain });
      } else {
          Cookies.remove('token', { domain: config.domain });
      }
    }, [token]);


    return (
        <Routes>
                <Route path="/login" element={Cookies.get('token', { domain: config.domain }) ? <Navigate to="/" /> : (
                    <Login />
                )}/>
                <Route path="/" element={!Cookies.get('token', { domain: config.domain }) ? <Navigate to="/login" /> : (
                    <Panel />
                    // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    //     <div>
                    //         <button onClick={getUserData}>Get User Data</button>
                    //         <button onClick={() => setToken('')}>Logout</button>
                    //         <p>{message}</p>
                    //         <p>{balance}</p>
                    //     </div>
                    // </div>
                )}/>
        </Routes>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);