"use client";
import styles from "./index.module.css";
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import config from "../config.js";
const host = config.api;

function Header({ balance, username }) {
    function logout() {
        Cookies.remove('token');
        window.location.href = "/login";
    }
    function toggleDropdown() {
        const dropdown = document.getElementById('dropdown-menu');
        const arrow = document.getElementById('dropdown-arrow');
        dropdown.style.display = dropdown.style.display === 'block' ? 'none' : 'block';
        arrow.textContent = dropdown.style.display === 'block' ? '▲' : '▼';
    }
    return (
        <header className={styles.header}>
            <div className={styles.headerLeft}>GMS</div>
            <div className={styles.headerRight}>
                <div className={styles.balanceContainer}>
                    <span className={styles.plusIcon}>+</span>
                    <span style={{fontWeight: "600"}}>${balance}</span>
                </div>
                <div className={styles.userDropdown}>
                    <span className={styles.username}></span>
                    <span className={styles.dropdownArrow} id="dropdown-arrow" onClick={toggleDropdown}>▼</span>
                    <div className={styles.dropdownMenu} id="dropdown-menu">
                        <span className={styles.dropdownHeader}>{username}</span>
                        <a href="#">Join Telegram</a>
                        <a href="#" onClick={logout}>logout</a>
                    </div>
                </div>
            </div>
        </header>
    )
}

function SideBar() {
    function toggleSidebar() {
        const sidebar = document.getElementById('sidebar');
        sidebar.classList.toggle(styles.collapsed);
    }
    return (
        <div className={styles.sidebar} id="sidebar">
            <ul className={styles.menu}>
                <li><a href="#" className={`${styles.tab} ${styles.active}`} data-tab="overview">
                    <span className={`${styles.span} ${styles.icon}`}><i class="fas fa-eye"></i></span>
                    <span className={styles.span}>Overview</span>
                </a></li>
                <li><a href="#" className={styles.tab} data-tab="dashboard">
                    <span className={`${styles.span} ${styles.icon}`}><i class="fas fa-tachometer-alt"></i></span>
                    <span className={styles.span}>GMS</span>
                </a></li>
                <li><a href="#" className={styles.tab} data-tab="support">
                    <span className={`${styles.span} ${styles.icon}`}><i class="fas fa-life-ring"></i></span>
                    <span className={styles.span}>Support</span>
                </a></li>
            </ul>        
            <div class="tabIndicator"></div>
            <button className={styles.hamburger} onClick={toggleSidebar}>☰</button>
        </div>
    )
}

function updateAccountTable(data) {
    const accountList = document.getElementById('account-list');
    accountList.innerHTML = '';

    data.forEach(holder => {
        const row = document.createElement('tr');

        const nameCell = document.createElement('td');
        nameCell.textContent = holder.holderName;
        nameCell.classList.add('holder-name-cell');
        nameCell.addEventListener('click', () => toggleDetails(row, holder));

        row.appendChild(nameCell);

        accountList.appendChild(row);
    });
}

function createAccountRow(account) {
    const accountRow = document.createElement('tr');

    const phoneCell = document.createElement('td');
    phoneCell.textContent = account.phoneNumber;
    accountRow.appendChild(phoneCell);

    const activationCell = document.createElement('td');
    const activationKeyShort = account.activationKey.substring(0, 8) + '...';
    activationCell.innerHTML = `<span class="activation-key">${activationKeyShort}
        <button class="copy-button" onclick="copyActivationKey('${account.activationKey}')"><i class="fas fa-copy"></i></button>
    </span>`;
    accountRow.appendChild(activationCell);

    const dateCell = document.createElement('td');
    dateCell.textContent = account.creationDate;
    accountRow.appendChild(dateCell);

    return accountRow;
}

function generatePhoneNumber(data) {
    return 
}
function generateActivationKey(data) {
    return 
}

function addNewNumbers(holder, accountsTable, count, addNumbersForm) {
    count = parseInt(count);

    if (isNaN(count) || count <= 0) {
        alert('Please select a valid number.');
        return;
    }

    if (holder.accounts.length + count > 10) {
        alert('Total number of accounts cannot exceed 10.');
        return;
    }

    for (let i = 0; i < count; i++) {
        const newAccount = {
            phoneNumber: generatePhoneNumber(),
            activationKey: generateActivationKey(),
            creationDate: new Date().toISOString().split('T')[0]
        };

        holder.accounts.push(newAccount);

        const newAccountRow = createAccountRow(newAccount);
        accountsTable.appendChild(newAccountRow);
    }

    addNumbersForm.parentNode.removeChild(addNumbersForm);

    if (holder.accounts.length < 10) {
        const addButton = addNumbersForm.parentNode.querySelector('.add-button');
        if (addButton) {
            addButton.style.display = 'inline-block';
        }
    } else {

        const addButton = addNumbersForm.parentNode.querySelector('.add-button');
        if (addButton) {
            addButton.parentNode.removeChild(addButton);
        }
    }
}

function showAddNumbersForm(holder, accountsTable, addButton) {

    const addNumbersForm = document.createElement('div');
    addNumbersForm.classList.add('add-numbers-form');

    const numberSelect = document.createElement('select');
    numberSelect.classList.add('number-select');

    for (let i = 1; i <= (10 - holder.accounts.length); i++) {
        const option = document.createElement('option');
        option.value = i;
        option.textContent = i;
        numberSelect.appendChild(option);
    }

    const createButton = document.createElement('button');
    createButton.textContent = 'Create';
    createButton.classList.add('create-button');
    createButton.addEventListener('click', () => {
        addNewNumbers(holder, accountsTable, numberSelect.value, addNumbersForm);
    });

    addNumbersForm.appendChild(numberSelect);
    addNumbersForm.appendChild(createButton);

    addButton.parentNode.insertBefore(addNumbersForm, addButton.nextSibling);
}

function showLoadingIndicator(addButton, holder, accountsTable) {

    addButton.style.display = 'none';

    const loadingIndicator = document.createElement('div');
    loadingIndicator.classList.add('loading-indicator');
    loadingIndicator.innerHTML = '<div class="spinner"></div>';
    addButton.parentNode.insertBefore(loadingIndicator, addButton.nextSibling);

    setTimeout(() => {
        loadingIndicator.parentNode.removeChild(loadingIndicator);
        showAddNumbersForm(holder, accountsTable, addButton);
    }, 3000);
}

function toggleDetails(row, holder) {

    if (row.nextSibling && row.nextSibling.classList.contains('details-row')) {
        row.parentNode.removeChild(row.nextSibling);
        return;
    }

    const detailsRow = document.createElement('tr');
    detailsRow.classList.add('details-row');
    const detailsCell = document.createElement('td');
    detailsCell.colSpan = 1;

    const detailsContainer = document.createElement('div');
    detailsContainer.classList.add('details-container');

    const planDisplay = document.createElement('div');
    planDisplay.classList.add('plan-display');
    planDisplay.textContent = `Plan: ${holder.plan}`;
    detailsContainer.appendChild(planDisplay);

    const accountsTable = document.createElement('table');
    accountsTable.classList.add('details-table');

    const headerRow = document.createElement('tr');
    const headers = ['Phone Number', 'Activation Key', 'Creation Date'];
    headers.forEach(text => {
        const th = document.createElement('th');
        th.textContent = text;
        headerRow.appendChild(th);
    });
    accountsTable.appendChild(headerRow);

    holder.accounts.forEach(account => {
        const accountRow = createAccountRow(account);
        accountsTable.appendChild(accountRow);
    });

    detailsContainer.appendChild(accountsTable);

    if (holder.accounts.length < 10) {
        const addButton = document.createElement('button');
        addButton.textContent = '+';
        addButton.classList.add('add-button');
        addButton.addEventListener('click', () => {
            showLoadingIndicator(addButton, holder, accountsTable);
        });
        detailsContainer.appendChild(addButton);
    }

    detailsCell.appendChild(detailsContainer);
    detailsRow.appendChild(detailsCell);

    row.parentNode.insertBefore(detailsRow, row.nextSibling);
}

function Panel() {
    const [username, setUsername] = useState('-');
    const [balance, setBalance] = useState('-');
    useEffect(() => {
        const tabs = document.querySelectorAll(`.${styles.tab}`);
        const sidebar = document.getElementById("sidebar");
        const indicator = document.createElement('div');
        const contents = document.querySelectorAll(`.tab-content`);
        let activeTab = document.querySelector(`.${styles.active}`);
        indicator.classList.add(`${styles.tabIndicator}`);
        sidebar.appendChild(indicator);

        function updateIndicator() {
            indicator.style.top = `${activeTab.offsetTop}px`;
            indicator.style.height = `${activeTab.offsetHeight}px`;
    
            if (sidebar.classList.contains('collapsed')) {
                indicator.style.left = '0';
            } else {
                indicator.style.left = '0';
            }
        }
        updateIndicator();

        tabs.forEach(tab => {
            tab.addEventListener('click', (e) => {
    
                activeTab.classList.remove('active');
                activeTab = e.currentTarget;
                activeTab.classList.add('active');
                updateIndicator();
                contents.forEach(content => {
                    if (content.id === `${activeTab.dataset.tab}-content`) {
                        content.style.display = 'block';
                    } else {
                        content.style.display = 'none';
                    }
                });
            });
        });

        contents.forEach(content => {
            if (content.id === `${activeTab.dataset.tab}-content`) {
                content.style.display = 'block';
            } else {
                content.style.display = 'none';
            }
        });
        (async () => {
            // console.log("wait");
            const token = Cookies.get('token');
            if (!token) {
                window.location.href = "/login";
            }
            try {
                const response = await axios.get(`${host}/users/me`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setBalance(response.data.balance);
                setUsername(response.data.username);
            } catch (error) {
                if (error.response?.status === 401) {
                    Cookies.remove('token', { domain: 'localhost' });
                    window.location.href = "/login";
                }
            }
        })();
        
        const accountList = document.getElementById('account-list');
        accountList.innerHTML = '';

        [].forEach(holder => {
            const row = document.createElement('tr');

            const nameCell = document.createElement('td');
            nameCell.textContent = holder.holderName;
            nameCell.classList.add('holder-name-cell');
            nameCell.addEventListener('click', () => toggleDetails(row, holder));

            row.appendChild(nameCell);

            accountList.appendChild(row);
        });
        
    }, []);
    function swapColumns(draggedColumnIndex, targetColumnIndex) {
        const table = document.getElementById("account-table");
    
        const headerRow = table.rows[0];
        headerRow.insertBefore(headerRow.children[draggedColumnIndex], headerRow.children[targetColumnIndex]);
        headerRow.insertBefore(headerRow.children[targetColumnIndex + 1], headerRow.children[draggedColumnIndex]);
    
        for (let i = 1; i < table.rows.length; i++) {
            const row = table.rows[i];
            row.insertBefore(row.children[draggedColumnIndex], row.children[targetColumnIndex]);
            row.insertBefore(row.children[targetColumnIndex + 1], row.children[draggedColumnIndex]);
        }
    }
    return (
        <div className={styles.container}>
            <Header balance={balance} username={username}/>
            <SideBar />
            <div className={styles.mainContent}>
                <div className={styles.content}>
                    <div class="tab-content" id="overview-content">
                        <div className={styles.balanceBox}>
                            <span className={styles.span}>Credits</span>
                            <span className={`${styles.span} ${styles.balanceAmount}`}>${balance}</span>
                            <button className={styles.addCreditsBtn}>+ Add Credits</button>
                        </div>
                        <div className={styles.depositHistory}>
                            <h3>Deposit History</h3>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>2024-11-20</td>
                                        <td>$100</td>
                                        <td>Completed</td>
                                    </tr>
                                    <tr>
                                        <td>2024-11-18</td>
                                        <td>$50</td>
                                        <td>Completed</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="tab-content" id="dashboard-content">
                        <div classname={styles.tableContainer}>
                            <table className={styles.table} id="account-table">
                                <thead>
                                    <tr>
                                        <th id="holder-name" onclick="sortByHolderName()" draggable="true" ondragstart="drag(event)" ondrop="drop(event)" ondragover="allowDrop(event)">Holder Name</th>
                                    </tr>
                                </thead>
                                <tbody id="account-list">
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="tab-content" id="support-content">
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Panel;